/* !
 * jQuery index v1.0.0
 * Copyright 2019, Connecty Inc.
 */
(function($, DIC){
	const a = 'is-active';
	const v = 'is-visible';
	const f = 'is-fixed';
	const slideSpeed = '400';

	const slider = () => {
		if($('.index-mainSlide').length) {
			$('.index-mainSlide').slick({
				autoplay: true,
				autoplaySpeed: 14000,
				slidesToShow: 1,
				slidesToScroll: 1,
				loop: true,
				arrows: true,
				prevArrow: '<a class="slick-prev" href="#"></a>',
				nextArrow: '<a class="slick-next" href="#"></a>',
				dots: false
			});
			$('.index-mainSlide').on('beforeChange', function(slick, currentSlide) {
				//スライドが切り替わる前
				if($('.index-mainSlide ._video video').length) {
					$('.index-mainSlide ._video video').get(0).currentTime=0;
				}
			});
			$('.index-mainSlide').on('afterChange', function(slick, currentSlide) {
				//スライドが切り替わった後
				if($('.index-mainSlide ._video video').length) {
					$('.index-mainSlide ._video video').get(0).play();
				}
			});
		}
	};
	const setTicker = () => {
		// const cutTickerText = (_cnt) => {
		// 	$('.index-ticker-item').find('.index-ticker-title-text').each((idx, ele) => {
		// 		var rawText = $(ele).text();
		// 		if(rawText.length > _cnt) {
		// 			$(ele).text(rawText.substring(0, _cnt) + '...');
		// 		}
		// 	});
		// };
		// if($(window).width() >= 1024) {
		// 	cutTickerText(40);
		// } else if($(window).width() < 1024 && $(window).width() >= 768) {
		// 	cutTickerText(30);
		// } else if($(window).width() < 768) {
		// 	cutTickerText(5);
		// }

		const $setElm = $('.js-ticker ul');

		$setElm.slick({
			autoplay: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplaySpeed: 5000,
			loop: true,
			speed: 1000,
			arrows: false,
			dots: false
		});
		setTimeout(function(){
			$('.js-ticker').addClass('is-active');
		}, 100);
	};
	const tabchange = () => {
		let $tabWrap = document.querySelectorAll('.js-tabWrap');
		let $tab = document.querySelectorAll('.js-tab');
		let $content = document.querySelectorAll('.js-tab-content');
		if ($tab.length) {
			// ハッシュタグで絞り込み
			if (location.hash) {
				let hash = location.hash.split('#')[1];
				$tab.forEach(ele => {
					ele.classList.remove(a);
					if (ele.getAttribute('data-target').match(hash)) {
						ele.classList.add(a);
					}
				});
				$content.forEach(ele => {
					ele.classList.remove(a);
					ele.classList.remove(v);
					if (ele.getAttribute('data-target').match(hash)) {
						ele.classList.add(a);
						setTimeout(() => { ele.classList.add(v); }, 200);
					}
				});
			}
			$tabWrap.forEach(wrapele => {
				wrapele.querySelectorAll('.js-tab').forEach(ele => {
					ele.addEventListener('click', () => {
						wrapele.querySelectorAll('.js-tab').forEach(ele2 => { ele2.classList.remove(a); })
						ele.classList.add(a);
						wrapele.querySelectorAll('.js-tab-content').forEach(ele => {
							ele.classList.remove(a);
							ele.classList.remove(v);
						});
						let $target;
						let target = ele.getAttribute('data-target');
						if (target === 'all') {
							$target = $content;
						} else {
							$target = document.querySelectorAll('[data-target="' + target + '"]');
						}
						$target.forEach(ele => {
							ele.classList.add(a);
							setTimeout(() => { ele.classList.add(v); }, 200);
						});
					});
				});
			});
		}
	};
	slider();
	tabchange();
	if(location.pathname.split('/')[1] === 'cn') {
		setTicker();
	}
})(window.jQuery, window.DIC);
